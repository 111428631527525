import React from "react";
import "../css/Hamburger.css";

const Hamburger = ({ state }) => {
  return (
    <button
      className={`hamburger hamburger--spin-r ${state ? "is-active" : ""}`}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  );
};

export default Hamburger;

// hamburger--3dx
// hamburger--3dx-r
// hamburger--3dy
// hamburger--3dy-r
// hamburger--3dxy
// hamburger--3dxy-r
// hamburger--arrow
// hamburger--arrow-r
// hamburger--arrowalt
// hamburger--arrowalt-r
// hamburger--arrowturn
// hamburger--arrowturn-r
// hamburger--boring
// hamburger--collapse
// hamburger--collapse-r
// hamburger--elastic
// hamburger--elastic-r
// hamburger--emphatic
// hamburger--emphatic-r
// hamburger--minus
// hamburger--slider
// hamburger--slider-r
// hamburger--spin
// hamburger--spin-r
// hamburger--spring
// hamburger--spring-r
// hamburger--stand
// hamburger--stand-r
// hamburger--squeeze
// hamburger--vortex
// hamburger--vortex-r

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Buyers.css";
import PicturesSection from "./PicturesSection";
import { Link } from "react-router-dom";
const content = [
  {
    num: 1,
    title: "be comfortable with what you can afford",
    content: [
      "Contact your financial advisor and obtain a pre-approved mortgage letter and/or have financial documents ready before contacting TORONTO Real Estate, Brokerage's REALTOR®.",
      "For residential real estate purchases buyers can expect a one-time cost as a down payment. This will likely be 5 to 25% of the total properties price. Fixed monthly expenses include mortgage payments, property taxes, and property operating costs such as utilities, water, gas, electrical, and maintenance. Some additional closing costs to keep in mind: land transfer tax, legal fees, home and property insurance.",
      "For commercial or industrial purchases buyers can expect to pay a one-time down payment of 30% or more. Some fixed monthly expenses include mortgage payments, and property operating costs such as utilities, water, gas, electrical, and maintenance.",
    ],
  },
  {
    num: 2,
    title: "estimate time for the buying process",
    content: [
      "Depending on your property requirements such as size, location, and availability, servicing time may vary. Please let your TORONTO Real Estate, Brokerage's REALTOR® know your specific details so that we can represent your best interests in the Toronto marketplace as documented in your buyer agreement at the time of commitment.",
      "Residential real estate sales usually take 30 to 90 days on average.",
      "Commercial real estate purchases may take from 30 days to a couple of years, depending on economic conditions and property availability.",
    ],
  },
  {
    num: 3,
    title: "contact us",
    content: [
      "Once you are comfortable with what you can afford, how much time you need, and you feel that you are ready to work with TORONTO Real Estate, Brokerage we invite you to register for our services by clicking contact us.",
    ],
    button: {
      text: "get in touch",
      link: "/contact",
    },
  },
];

const Buyers = () => {
  return (
    <Row className="buyers">
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 8, offset: 2 }}
        className="lato "
      >
        <div className="container">
          <div className="contact-header playfair">Buyers</div>

          <div className="contact-text playfair ml-3" data-aos="fade-up">
            Committing to buy real estate in{" "}
            <span className="red-text">TORONTO</span> can be overwhelming and
            exciting. We're here to help!
          </div>
          <p>
            We understand that choosing the right brokerage to represent your
            best interests in the buying process is important to you. We love to
            help you make good choices and informed decisions. Once you decide
            to move forward with well-experienced TORONTO Real Estate, Brokerage
            REALTORS® we will guide you through the entire buying process.
          </p>
          <p>
            In order to serve you better here are a few steps we recommend you
            follow before
            <Link to="/contact">
              <span className="red-text"> contacting us </span>
            </Link>{" "}
            and registering to work with our brokerage.
          </p>
          <PicturesSection />

          {content.map((el, i) => {
            return <ListItem content={el} key={i} />;
          })}
        </div>
      </Col>
    </Row>
  );
};

const ListItem = ({ content }) => {
  return (
    <div className="list-item" data-aos="fade-right">
      <h4 className="playfair">
        <span className="playfair num">{content.num}. </span>
        {content.title}
      </h4>
      <div>
        {content.content.map((el, i) => {
          return <p key={i}>{el}</p>;
        })}
        {content.button && (
          <Link className="contact-button shadow ml-4" to={content.button.link}>
            {content.button.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Buyers;

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Landing.scss";
import Header from "./Header";
import AreasSection from "./AreasSection";
import PicturesSection from "./PicturesSection";
import InfoSection from "./InfoSection";
import PropertiesSection from "./PropertiesSection";
import Working from "./Working";

const LandingPage = () => {
  return (
    <Row>
      <Header />
      <Col xs={{ span: 12, offset: 0 }} className="rainbow"></Col>
      <AreasSection />
      <PicturesSection />
      <InfoSection />
      <Col xs={{ span: 12, offset: 0 }} className="rainbow"></Col>
      <PropertiesSection />
      <Col xs={{ span: 12, offset: 0 }} className="rainbow"></Col>
      <Working />
    </Row>
  );
};

export default LandingPage;

import React from "react";
import Carousel from "react-bootstrap/Carousel";

const PicturesCarousel = ({ pics }) => {
  const shuffle = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };
  const interval = () => {
    return Math.floor(Math.random() * 5000) + 2000;
  };
  return (
    <div className="picture-carousel">
      <Carousel
        fade
        controls={false}
        pause={false}
        interval={interval()}
        indicators={false}
      >
        {shuffle(pics).map((el, i) => {
          return (
            <Carousel.Item key={i}>
              <img
                className="d-block w-100"
                src={`./images/${el}`}
                alt="First slide"
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default PicturesCarousel;

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropertyType from "./PropertyType";

const propertyType = [
  ["Detached homes", "Development vacant land", "Land assembly "],
  [
    "Stores with apartments above",
    "Retail",
    "Brick/beam office spaces",
    "Multi-units",
    "Vacant land",
    "Mixed use",
    "Redevelopment value add",
  ],
  ["Warehouses", "Vacant land"],
];

const PropertiesSection = () => {
  return (
    <>
      <Col xs={{ span: 12, offset: 0 }} sm={{ span: 8, offset: 2 }}>
        <div className="contact-header playfair">Properties</div>

        <div className="contact-text playfair ml-3" data-aos="fade-up">
          <span className="red-text"> Residential </span>-
          <span className="red-text"> Commercial </span>-
          <span className="red-text">Industrial</span>, it's what we do. That's
          why we're Canada's #1 choice for Toronto Real Estate.
        </div>
      </Col>
      <Col xs={{ span: 12, offset: 0 }} className="landing-properties">
        <Row>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="landing-property "
          >
            <PropertyType
              icon="home"
              type="residential"
              content={propertyType[0]}
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="landing-property"
          >
            <PropertyType
              icon="store"
              type="commercial"
              content={propertyType[1]}
            />
          </Col>
          <Col
            xs={{ span: 12, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            className="landing-property"
          >
            <PropertyType
              icon="warehouse"
              type="industrial"
              content={propertyType[2]}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default PropertiesSection;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from "./Hamburger";
import "../css/Navigation.css";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="navigation">
      <div className="nav-button" onClick={toggleMenu}>
        <Hamburger state={menuOpen} />
      </div>

      <div
        className={`nav-window-left ${menuOpen ? "menu-open" : "menu-closed"}`}
      >
        <div className="grey-overlay">
          <div className="nav-window-content  ">
            <div className="cta-header">get in touch</div>
            <div className="cta-content text-justify">
              Toronto Real Estate Brokerage is here for you! Helping answer real
              estate questions is what we do best. Reach out to get in touch
              with one of our local experts for some industry leading advice.
            </div>
            <Link to="/contact" className="cta-button" onClick={toggleMenu}>
              let's talk!
            </Link>
          </div>
        </div>
      </div>
      <div
        className={`nav-window-right ${menuOpen ? "menu-open" : "menu-closed"}`}
      >
        <div className="nav-window-content">
          <Link className="nav-link zoom" to="/" onClick={toggleMenu}>
            home
          </Link>
          <Link className="nav-link zoom" to="/buyers" onClick={toggleMenu}>
            buyers
          </Link>
          <Link className="nav-link zoom" to="/sellers" onClick={toggleMenu}>
            sellers
          </Link>
          <Link className="nav-link zoom" to="/contact" onClick={toggleMenu}>
            contact
          </Link>

          <a
            className="nav-link zoom"
            target="_blank"
            href="https://www.instagram.com/toronto.real.estate.brokerage/"
            rel="noreferrer"
          >
            <i className="instagram icon"></i>
          </a>
          <a className="nav-link zoom" href="tel:416-844-9449" rel="noreferrer">
            <i className="phone  icon"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navigation;

import React, { useState } from "react";

const PropertyType = ({ icon, type, content }) => {
  const [hover, setHover] = useState(false);
  const [clicked, setClicked] = useState(false);
  return (
    <div className={`property-type`}>
      <div
        className={`property-type-intro ${clicked ? "slide-out" : "slide-in"}`}
      >
        <i
          className={`zoom pt-2 fas fa-${icon}`}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
          onClick={() => setClicked(true)}
          data-aos="flip-up"
        />
        <h3 className={`playfair ${hover && "show"}`}>{type}</h3>
      </div>
      <div
        className={`property-type-content ${
          !clicked ? "slide-out" : "slide-in"
        }`}
      >
        <i className="fas fa-times" onClick={() => setClicked(false)}></i>
        <ul>
          {content.map((el, i) => {
            return (
              <li key={i} className="text-left lato">
                {el}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PropertyType;

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/PicturesSection.css";
import PicturesCarousel from "./PicturesCarousel";

const pics = [
  ["1.png", "2.png", "3.png", "4.png", "5.png"],
  ["6.png", "7.png", "8.png", "9.png"],
  ["10.png", "11.png", "12.png", "13.png"],
  ["14.png", "15.png", "16.png", "17.png"],
];

const PicturesSection = () => {
  return (
    <Col
      xs={{ span: 12, offset: 0 }}
      sm={{ span: 8, offset: 2 }}
      lg={{ span: 12, offset: 0 }}
    >
      <Row className="m-4">
        <Col
          xs={{ span: 6, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          className="picture-col"
        >
          <PicturesCarousel pics={pics[0]} />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          className="picture-col"
        >
          <PicturesCarousel pics={pics[1]} />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          className="picture-col"
        >
          <PicturesCarousel pics={pics[2]} />
        </Col>
        <Col
          xs={{ span: 6, offset: 0 }}
          lg={{ span: 3, offset: 0 }}
          className="picture-col"
        >
          <PicturesCarousel pics={pics[3]} />
        </Col>
      </Row>
    </Col>
  );
};

export default PicturesSection;

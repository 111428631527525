import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import App from "./components/App";
import "./css/Fonts.css";
import "./css/App.css";
import "./css/ScreenSizing.css";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

ReactDOM.render(<App />, document.getElementById("root"));

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Buyers.css";
import PicturesSection from "./PicturesSection";
import { Link } from "react-router-dom";
const content = [
  {
    num: 1,
    title: "getting ready for the real estate market",
    content: [
      "We understand either your property has served its economic purpose or certain events have happened and now you are ready to sell and move to the next stage.",
      "Here are a few steps to consider before list your property on the market and committing to selling with TORONTO's Real Estate, Brokerage.",
      "When buyers compare properties available on the market, be it residential or commercial, they will pay top dollar for move-in condition properties and less for properties that require work or improvement.",
      "Consider walking through the property with TORONTO Real Estate, Brokerage REALTORS so that we can provide professional recommendations.",
    ],
  },
  {
    num: 2,
    title: "effective market positioning",
    content: [
      "To determine the market value of your property, we will provide a comparative market analysis of past sales in your specific area. With your property priced closer to actual market value we’ll be able to maximize the number of interested buyers resulting in more offers for you to choose from.",
      "Note: The risk of overpricing is that no prudent motivated buyers will make offers when they see other similar properties priced closer to market value. Remember that there are many properties in the marketplace, consider pricing right if you are ready to move.",
    ],
  },
  {
    num: 3,
    title: "estimate time for the selling process",
    content: [
      "Residential real estate sales usually take 30 to 90 days on average.",
      "Commercial real estate purchases may take from 30 days to a couple of years.",
    ],
  },
  {
    num: 4,
    title: "contact us",
    content: [
      "Once you are ready to work with TORONTO Real Estate, Brokerage we invite you to register for our services by contacting us.",
    ],
    button: {
      text: "get in touch",
      link: "/contact",
    },
  },
];

const Sellers = () => {
  return (
    <Row className="buyers">
      <Col
        xs={{ span: 12, offset: 0 }}
        sm={{ span: 8, offset: 2 }}
        className="lato "
      >
        <div className="container">
          <div className="contact-header playfair">Sellers</div>

          <div className="contact-text playfair ml-3" data-aos="fade-up">
            We understand that choosing the right brokerage to represent your
            best interests in the selling process is important to you.
          </div>
          <p>
            We love to help you make good choices and informed decisions. We are
            here to help you. Once you decide to move forward with
            well-experienced TORONTO Real Estate, Brokerage REALTORS® we will
            guide you through the entire selling process.
          </p>
          <p>
            In order to serve you better here are a few steps we recommend you
            follow before
            <Link to="/contact">
              <span className="red-text"> contacting us </span>
            </Link>{" "}
            and registering to work with our brokerage.
          </p>
          <PicturesSection />

          {content.map((el, i) => {
            return <ListItem content={el} key={i} />;
          })}
        </div>
      </Col>
    </Row>
  );
};

const ListItem = ({ content }) => {
  return (
    <div className="list-item" data-aos="fade-right">
      <h4 className="playfair">
        <span className="playfair num">{content.num}. </span>
        {content.title}
      </h4>
      <div>
        {content.content.map((el, i) => {
          return <p key={i}>{el}</p>;
        })}
        {content.button && (
          <Link className="contact-button shadow ml-4" to={content.button.link}>
            {content.button.text}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sellers;

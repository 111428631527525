import React from "react";
import Col from "react-bootstrap/Col";
const Header = () => {
  return (
    <Col xs={{ span: 12, offset: 0 }} className="landing-hero">
      <div className="landing-hero-filter">
        <div
          className="landing-hero-header playfair"
          data-aos="fade"
          data-aos-duration="2000"
        >
          Toronto Real Estate Brokerage Toronto Real Estate Brokerage Toronto
          Real Estate Brokerage Toronto Real Estate Brokerage Toronto Real
          Estate Brokerage Toronto Real Estate Brokerage Toronto Real Estate
          Brokerage
        </div>
        <img
          src="./images/treb.jpeg"
          alt="Toronto Real Estate Board Logo"
          className="landing-hero-logo"
        />
      </div>
    </Col>
  );
};

export default Header;

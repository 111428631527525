import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";
import Navigation from "./Navigation";
import LandingPage from "./LandingPage";
import Buyers from "./Buyers";
import Sellers from "./Sellers";
import Contact from "./Contact";
import Footer from "./Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop>
          <div>
            <Navigation />
            <Switch>
              <Route path="/" exact component={LandingPage} />
              <Route path="/buyers" exact component={Buyers} />
              <Route path="/sellers" exact component={Sellers} />
              <Route path="/contact" exact component={Contact} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;

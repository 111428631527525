import React from "react";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";

const Working = () => {
  return (
    <Col xs={{ span: 12, offset: 0 }} className="lato working">
      <div className="container">
        <h1 className="playfair">
          WORKING WITH <span className="red-text">TORONTO</span> REAL ESTATE,
          BROKERAGE
        </h1>

        <p data-aos="fade-right">
          If you are interested in having your best interests represented by the
          TORONTO Real Estate, Brokerage while{" "}
          <span className="red-text">BUYING</span> or{" "}
          <span className="red-text">SELLING</span> TORONTO and TORONTO WEST
          properties we invite you to provide more information by filling out
          our{" "}
          <strong className="red-text">
            <Link to="/contact">CONTACT</Link>
          </strong>{" "}
          page.{" "}
        </p>

        <p data-aos="fade-right">
          In Ontario, the real estate profession is governed by Real Estate and
          Business Brokers Act, 2002, and Associated Regulations REBBA 2002 or
          Act administered by the Real Estate Council of Ontario RECO. All
          Ontario REALTORS® are registered under the Act and governed by its
          provisions.
        </p>

        <p data-aos="fade-right">
          REALTORS® are governed by the legal concept of "agency". An agent is
          legally obligated to look after the interests of the person he or she
          represents. The agent must be loyal to that person as long as the
          written agreement is present between the consumer and the brokerage.
        </p>

        <p data-aos="fade-right">
          The Act also requires that the brokerage through REALTORS® of that
          brokerage explain the types of service alternatives available to
          consumers and the services the brokerage will be providing. The
          brokerage must document the relationship being created between the
          brokerage and the consumer, and submit it to the consumer for his/her
          approval and signature. The common relationships are "client" and
          "customer" available in the marketplace.
        </p>
      </div>
    </Col>
  );
};

export default Working;

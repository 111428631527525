import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/InfoSection.css";

const content = [
  {
    title: "Searching for properties?",
    content:
      "Check out RESIDENTIAL and COMMERCIAL properties for sale at www.realtor.ca.",
    pic: "4.png",
  },
  {
    title: "Looking for real estate services?",
    content:
      "We are full service residential and commercial real estate brokerage. We provide services at market rate commissions for market valued properties in the Downtown and Toronto West areas only. We are here to help when you need us!",
    pic: "2.png",
  },
  {
    title: "Looking for information?",
    content:
      "TORONTO Real Estate, Brokerage REALTORS® are members of the Toronto Real Estate Board, the Ontario Real Estate Association, and the Canadian Real Estate Association. We are Canada's Choice of REALTORS® locally based in Toronto, Ontario, Canada.",
    pic: "14.png",
  },
];

const InfoSection = () => {
  return (
    <Col xs={{ span: 8, offset: 2 }} className="lato info">
      <div className="contact-header playfair">Info</div>

      <div className="contact-text playfair ml-3" data-aos="fade-up">
        We're here to help whenever you need us!
      </div>
      <Row>
        <InfoSubSection
          title={content[0].title}
          content={content[0].content}
          pic={content[0].pic}
          link
          left
        />
        <InfoSubSection
          title={content[1].title}
          content={content[1].content}
          pic={content[1].pic}
          right
        />
        <InfoSubSection
          title={content[2].title}
          content={content[2].content}
          pic={content[2].pic}
          left
        />
      </Row>
    </Col>
  );
};

export default InfoSection;

const InfoSubSection = ({ title, content, link, left, right, pic }) => {
  return (
    <>
      <Col
        xs={{ span: 6, offset: 0 }}
        className={`p-0 d-none ${right ? "d-none" : " d-md-block "}`}
      >
        <div
          className="info-pic"
          style={{ backgroundImage: `url("./images/${pic}")` }}
        ></div>
      </Col>
      <Col
        xs={{ span: 12, offset: 0 }}
        md={{ span: 6, offset: 0 }}
        className="p-0 info-content mt-4 mb-4"
      >
        <hr className="d-block d-md-none"></hr>
        <div>
          {/* <div > */}
          {/* <div data-aos="fade-up"> */}

          <h4
            className="playfair"
            data-aos={`${left && "fade-left"} ${right && "fade-right"}`}
          >
            {title}
          </h4>
          <p data-aos={`${left && "fade-left"} ${right && "fade-right"}`}>
            {content}
          </p>
          <a
            className={`zoom ${!link && "d-none"}`}
            href="http://www.realtor.ca"
            target="_blank"
            rel="noreferrer"
          >
            <img src="./images/click.png" alt="click here" />
          </a>
        </div>
      </Col>
      <Col
        xs={{ span: 6, offset: 0 }}
        className={`p-0 d-none ${left ? "d-none" : " d-md-block "}`}
      >
        <div
          className="info-pic"
          style={{ backgroundImage: `url("./images/${pic}")` }}
        ></div>
      </Col>
    </>
  );
};

import React from "react";
import Col from "react-bootstrap/Col";

const AreasSection = () => {
  return (
    <Col xs={{ span: 12, offset: 0 }} sm={{ span: 8, offset: 2 }}>
      <div className="contact-header playfair">Our City</div>

      <div className="contact-text playfair ml-3" data-aos="fade-up">
        We're proudly serving our community in the
        <span className="red-text"> Downtown </span> and
        <span className="red-text"> Toronto West </span> neighbourhoods
      </div>
    </Col>
  );
};

export default AreasSection;

import React from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <Row>
        <Col xs={{ span: 8, offset: 2 }} sm={{ span: 4, offset: 0 }}>
          <Link to="/">
            <img
              src="./images/treb.jpeg"
              alt="Toronto Real Estate Board Logo"
              className="footer-logo mt-4 mb-4"
            />
          </Link>
        </Col>
        <Col xs={{ span: 12, offset: 0 }} sm={{ span: 4, offset: 0 }}>
          <div className="footer-text lato ">
            <div>
              <span className="red-text">DOWNTOWN</span> and{" "}
              <span className="red-text">TORONTO WEST</span>
            </div>
            <div>Residential – Commercial – Industrial</div>
            <div>Copyright © 2021. All rights reserved.</div>
          </div>
        </Col>
        <Col xs={{ span: 8, offset: 2 }} sm={{ span: 4, offset: 0 }}>
          <a
            href="https://www.bellwoodsweb.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="./images/bw.png"
              alt="bellwoods web development"
              className="footer-logo zoom mt-4 mb-4"
            />
          </a>
        </Col>
        <Col xs={{ span: 10, offset: 1 }} className=" mb-4 footer-notes">
          <hr className=" mb-4" />
          <p>
            TORONTO Real Estate, Brokerage is not intended to work with or
            represent BUYERS or SELLERS who have written representation
            agreements with other TRREB member brokerages at the time of
            registration. Buyers and/or Sellers are advised to disclose to their
            TORONTO Real Estate, Brokerage that material fact.
          </p>

          <p>
            TORONTO Real Estate, Brokerage is a member of Toronto Real Estate
            Board, Ontario Real Estate Association and the CANADIAN Real Estate
            Association.{" "}
          </p>

          <p>
            Please note that Federal legislation requires REALTORS® to verify
            the identity of sellers and buyers with whom they are working and
            will ask for your personal information in order to provide services.{" "}
          </p>

          <p>
            The MLS® mark and associated logos identify professional services
            rendered by REALTOR® members of CREA to effect the purchase, sale
            and lease of real estate as part of a cooperative selling system.
          </p>

          <p>
            The trademarks REALTOR®, REALTORS® and the REALTOR® logo are
            controlled by The Canadian Real Estate Association (CREA) and
            identify real estate professionals who are members of CREA. The
            trademarks MLS®, Multiple Listing Service® and the associated logos
            are owned by CREA and identify the quality of services provided by
            real estate professionals who are members of CREA.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;

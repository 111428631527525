import React from "react";
import axios from "axios";
import { Dropdown } from "semantic-ui-react";

import "../css/Contact.css";

// const API_PATH = "http://www.torontoreb.com/php/contactForm.php";
const API_PATH = "/php/contactForm.php";

const realtorOptions = [
  {
    key: 1,
    text: "Yes",
    value: "Yes",
  },
  {
    key: 2,
    text: "No",
    value: "No",
  },
];

const whoOptions = [
  {
    key: 1,
    text: "Buyer",
    value: "Buyer",
  },
  {
    key: 2,
    text: "Seller",
    value: "Seller",
  },
  {
    key: 3,
    text: "Landlord",
    value: "Landlord",
  },
  {
    key: 4,
    text: "Tenant",
    value: "Tenant",
  },
  {
    key: 5,
    text: "Potential partner",
    value: "Potential partner",
  },
  {
    key: 6,
    text: "Just interested in info",
    value: "Just interested in info",
  },
];
const typeOptions = [
  {
    key: 1,
    text: "Residential",
    value: "Residential",
  },
  {
    key: 2,
    text: "Commercial",
    value: "Commercial",
  },
  {
    key: 3,
    text: "Industrial",
    value: "Industrial",
  },
];
const whenOptions = [
  {
    key: 1,
    text: "Now",
    value: "Now",
  },
  {
    key: 2,
    text: "Within 30 days",
    value: "Within 30 days",
  },
  {
    key: 3,
    text: "Within 60 days",
    value: "Within 60 days",
  },
  {
    key: 4,
    text: "Within 90 days",
    value: "Within 90 days",
  },
  {
    key: 5,
    text: "Not sure yet",
    value: "Not sure yet",
  },
];

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      realtor: "",
      message: "",
      who: "",
      type: "",
      when: "",
      checkbox: false,
      errors: [false, false, false, false],
      mailSent: false,
      error: null,
    };
  }

  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.validate()) {
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: this.state,
      })
        .then((result) => {
          this.setState({
            mailSent: result.data.sent,
          });
          this.clearForm();
        })
        .catch((error) => {
          this.setState({ error: error.message });
          this.clearForm();
        });
    }
  };

  validate = () => {
    const { name, email, realtor, message, checkbox } = this.state;
    const tmpErr = [false, false, false, false, false];
    let result = true;

    if (!name) tmpErr[0] = true;
    if (!realtor) tmpErr[1] = true;
    if (!this.validateEmail(email)) tmpErr[2] = true;
    if (!message) tmpErr[3] = true;
    if (!checkbox) tmpErr[4] = true;
    this.setState({ errors: tmpErr });
    tmpErr.map((el) => {
      if (el) {
        result = false;
        return true;
      }
      return false;
    });
    return result;
  };

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onNameChange = (e) => {
    this.setState({ name: e.target.value });
    this.setError(e.target.value, 0);
  };

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
    this.setError(e.target.value, 1);
  };
  onRealtorChange = (e, value) => {
    this.setState({ realtor: value });
    this.setError(value, 2);
  };

  onMessageChange = (e) => {
    this.setState({ message: e.target.value });
    this.setError(e.target.value, 3);
  };

  onCheckBoxChange = (e) => {
    this.setState({ checkbox: e.target.checked });
    this.setError(e.target.checked, 4);
  };

  setError = (value, index) => {
    const tmp = this.state.errors.map((el, i) => (i === index ? !value : el));
    this.setState({ errors: tmp });
  };

  clearForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      checkbox: false,
      errors: [false, false, false, false, false],
    });
  };

  render() {
    return (
      <div className="contact">
        <div className="ui container contact lato">
          {/* <div className="contact-decoration"></div> */}
          <div className="contact-header playfair">Contact</div>

          <div className="contact-text playfair" data-aos="fade-up">
            We'd love to hear from you. Whether you're in the business of{" "}
            <span className="red-text">buying </span>
            or <span className="red-text">selling</span>, we've got you covered!
          </div>
          <form
            action="#"
            className={`ui large form ${
              this.state.mailSent || this.state.error ? "contact-hidden" : ""
            }`}
          >
            <div className="two fields">
              <div className={`field ${this.state.errors[0] ? "error" : ""}`}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="What's your name?"
                  onChange={(e) => this.onNameChange(e)}
                  value={this.state.name}
                />
              </div>

              <div className={`field ${this.state.errors[1] ? "error" : ""}`}>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="What's your email?"
                  onChange={(e) => this.onEmailChange(e)}
                  value={this.state.email}
                />
              </div>
            </div>
            <div className="field ">
              <div className={`field ${this.state.errors[3] ? "error" : ""}`}>
                <textarea
                  id="message"
                  name="message"
                  placeholder="What can we do for you?"
                  onChange={(e) => this.onMessageChange(e)}
                  value={this.state.message}
                  rows="6"
                />
              </div>
            </div>

            {/*  */}
            <div className="two fields">
              <div className={`field ${this.state.errors[2] ? "error" : ""}`}>
                <Dropdown
                  placeholder="Are you working with a REALTOR®?"
                  selection
                  clearable
                  options={realtorOptions}
                  // onChange={(e, { value }) => this.setState({ realtor: value })}
                  onChange={(e, { value }) => this.onRealtorChange(e, value)}
                />
              </div>
              <div className={`field `}>
                <Dropdown
                  placeholder="Who are you?"
                  selection
                  clearable
                  options={whoOptions}
                  onChange={(e, { value }) => this.setState({ who: value })}
                />
              </div>
            </div>
            <div className="two fields">
              <div className={`field`}>
                <Dropdown
                  placeholder="Type of service needed"
                  selection
                  clearable
                  options={typeOptions}
                  onChange={(e, { value }) => this.setState({ type: value })}
                />
              </div>
              <div className={`field`}>
                <Dropdown
                  placeholder="When will you be ready?"
                  selection
                  clearable
                  options={whenOptions}
                  onChange={(e, { value }) => this.setState({ when: value })}
                />
              </div>
            </div>
            {/*  */}

            <div className="field text-center">
              <button
                type="submit"
                name="submit"
                className="contact-button shadow "
                onClick={(e) => this.handleFormSubmit(e)}
              >
                send us a message
              </button>
            </div>

            <div className="field text-center ">
              <div className="ui toggle checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => this.onCheckBoxChange(e)}
                />
                <label
                  className={`coloring ${this.state.errors[4] ? "error" : ""}`}
                ></label>
              </div>
              <p>i'm {`${this.state.checkbox ? "not" : ""}`} a robot</p>
            </div>
          </form>
          <div className="text-center">
            {this.state.mailSent && (
              <>
                <h1 className="msg-sent-header playfair">thank you!</h1>
                <h4 className="msg-sent-text">
                  we'll get back to you as soon as possible
                </h4>
              </>
            )}
            {this.state.error && (
              <>
                <h1 className="msg-sent-header playfair">whoops...</h1>
                <h4 className="msg-sent-text">
                  something went wrong, please email{" "}
                  <span className="red-text">
                    <a
                      href="mailto:webmaster@example.com"
                      target="_blank"
                      rel="noreferrer"
                      className="red-text"
                    >
                      lk@torontoreb.com
                    </a>
                  </span>
                </h4>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
